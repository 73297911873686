import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import config from "../../utils/config";
import {
    getInstanceAxios,
    getSyncDateNow,
    prepareEventData,
    prepareEventRegistrationData,
    prepareUserData
} from "../../utils/helpers";
import socketModule from "../../utils/socketModule";
import { setCurrentUserAction, setDatingToken, setOtherUserAction } from "../../store/users/actions";
import {
    getNextViewBasedOnUserSession,
    logging,
    setAllDataProvidedAction,
    setCurrentEventAction,
    setCurrentUserSessionAction,
    setDirectModeAction,
    setLoadedContainer,
    setSurveySubmitButtonAction,
    switchView
} from "../../store/controller/actions";
import {
    getCurrentEventSessionAction,
    setEventRegistrationInfoAction
} from "../../store/checkin/actions";


const LoadingContainer = (props) => {
    const { currentSession: { roomNumber }, currentUser, currentEvent } = props;
    const [nextView, setNextView] = useState(null);
    const loaded = props.isLoadedContainer;

    const getUid = async () => {
        const userResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/users/get-uid`, { crossDomain: true});
        if (userResponse.data.status === 200) {
            return userResponse.data.userId;
        } else {
            return null;
        }
    };

    useEffect(() => {
        (async () => {
            document.body.style.backgroundColor = "#000";

            //disable browser back button
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.go(1);
            };

            const eventId = props.match.params.event_id;
            const userId = props.match.params.user_id || props.currentUser.userId || await getUid();

            await setDatingToken(userId, eventId);

            props.setDirectModeAction(false);

            // Ugo Integration:
            const location = window.location.href;
            if (eventId) {
                const date = new Date(currentEvent.internalEndDateAndTime);
                date.setHours(date.getHours() + 2);
                window.parent.postMessage({
                    type: "ugo-integration",
                    currentUrl: location,
                    eventId: eventId,
                    exp: date
                }, "*");
            }

            if (!props.currentUser.userId) {
                const userResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/users/${userId}?event_id=${eventId}`, {
                    crossDomain: true
                });
                if (userResponse.data.status === 200) {
                    props.setCurrentUserAction(prepareUserData(userResponse.data.userData));
                    if (userResponse.data.userData && userResponse.data.userData.userEventRegistration) {
                        props.setEventRegistrationInfoAction(prepareEventRegistrationData(userResponse.data.userData.userEventRegistration));
                    }
                } else {
                    console.error(userResponse.data.message);
                }
            }

            if (!currentEvent.eventId) {
                const eventResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/events/event-from-live-event/${eventId}`, {
                    crossDomain: true
                });
                if (eventResponse && eventResponse.data.status === 200) {
                    props.setCurrentEventAction(prepareEventData(eventResponse.data.eventData, eventResponse.data.accountData));
                } else if(eventResponse) {
                    console.error(eventResponse.data.message);
                }
            }

            if (getSyncDateNow() > Date.parse(currentEvent.internalEndDateAndTime)) {
                props.setSurveySubmitButtonAction("exit");
            }

            if (currentEvent.eventId && currentUser.userId) {
                socketModule.socket.emit("store-to-redis", {
                    userId: currentUser.userId,
                    eventId: currentEvent.eventId
                });
                socketModule.socket.emit("connect_to_self_room", {
                    eventId: currentEvent.eventId,
                    userId: currentUser.userId
                });
            }
        })();
    }, [currentEvent]);

    useEffect(() => {
        (async () => {
            if (currentEvent && currentEvent.eventId) {
                if (!nextView) {
                    if (!loaded) {
                        props.setLoadedContainer(true);
                        const nextViewData = await props.getNextViewBasedOnUserSession();
                        setNextView(nextViewData);
                    }
                } else {
                    const params = {
                        eventId: currentEvent.eventId,
                        userId: currentUser.userId,
                        roomNumber
                    };
                    socketModule.socket.emit("bio_progress.complete_prior_call", params);
                    socketModule.socket.emit("bio_progress.complete_matches", params);

                    props.logging({
                            message: nextView === "video" ?
                                `Switched to <b>${nextView}</b> screen. Room number: ${roomNumber} | <small> LoadingContainer(124) </small>` :
                                `Switched to <b>${nextView}</b> screen. | <small> LoadingContainer(124) </small>`
                        }
                    );

                    if (nextView === "holding") {
                        props.switchView("holding");
                    } else if (currentEvent && currentEvent.eventId && roomNumber) {
                        if (nextView === "video") {
                            const thirdJoined = props.otherUserB.userId !== "";
                            socketModule.socket.emit("connect_to_room", {
                                userId: currentUser.userId,
                                eventId: currentEvent.eventId,
                                roomNumber,
                                thirdJoined
                            });
                            props.switchView("video");
                            socketModule.socket.emit("bio_progress.complete_survey", params);
                            const path = `/live-event/${currentEvent.eventId}/${roomNumber}`;
                            if (!document.location.href.includes(roomNumber)) props.history.push(path);
                        } else if (nextView === "survey") {
                            props.switchView("survey");
                            const path = `/live-event/${currentEvent.eventId}/${roomNumber}`;
                            if (!document.location.href.includes(roomNumber)) props.history.push(path);
                        } else {
                            props.switchView(nextView);
                        }
                    }
                    await props.getCurrentEventSessionAction();
                    props.setAllDataProvidedAction();
                }
            }
        })();
    }, [currentEvent, roomNumber, nextView, loaded]);

    return null;
};

export default withRouter(connect(
    store => ({
        currentUser: store.users.currentUser,
        otherUserB: store.users.otherUserB,
        currentEvent: store.controller.currentEvent,
        currentSession: store.controller.currentSession,
        videoContainerLoaded: store.controller.videoContainerLoaded,
        eventRegistration: store.checkin.eventRegistration,
        isLoadedContainer: store.event.isLoadedContainer
    }),
    {
        setAllDataProvidedAction,
        setCurrentUserAction,
        setOtherUserAction,
        switchView,
        setCurrentEventAction,
        setCurrentUserSessionAction,
        getNextViewBasedOnUserSession,
        setSurveySubmitButtonAction,
        setDirectModeAction,
        logging,
        getCurrentEventSessionAction,
        setEventRegistrationInfoAction,
        setLoadedContainer
    }
)(LoadingContainer));
