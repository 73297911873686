import types from "../constans";

const initialState = {
    loggedIn: false,
    steps: [],
    options: {},
    userOnline: false,
    checkinReady: false,
    eventRegistration: {},
    eventRegistrations: [],
    previouslyMetUsersIds: [],
    onlineUsersIds: [],
    currentSessionNumber: 0,
    eventSessions: [],
    needShowGreenScreen: true,
    showCheckinFishbowlPopup: false,
    showCheckinFishbowlMiniPopup: false,
    showTargetJoinPopup: null,
    showTargetJoinMiniPopup: false,
    hideCheckinFishbowlPopup: false,
    hideTargetJoinPopup: false,
    fishbowlFunctionalityBlocked: false,
    fishbowlMiniSearchMode: true,
    currentStream: null,
    videoSources: {
        organizerVideo: null,
        welcomeVideo: 'https://vimeo.com/431136818'
    },
    availableToMatch: true,
    detectRtcData: null,
    kickoffIsOver: false,
    isHiddenKickoff: false,
    lastMeetOver: false,
    inProgressChangeStep: false,
    lastMeetPassed: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_EVENT_REGISTRATIONS_EVENT_TAGS:
            return {
                ...state,
                eventRegistrations: state.eventRegistrations.map(reg => {
                    const updatedCurrentEr = action.updatedEventRegistrations.find(updEr => updEr.id === reg.id);
                    if (!updatedCurrentEr) {
                        return {...reg}
                    }
                    return {
                        ...reg,
                        event_tags: updatedCurrentEr.event_tags
                    }
                })
            }
        case types.UPDATE_EVENT_REGISTRATION_ORGANIZER_MODE:
            return {
                ...state,
                eventRegistrations: state.eventRegistrations.map(reg => {
                    return {
                        ...reg, bubble_user: reg.bubble_user,
                        organizer_mode: reg.id === action.id ? 'yes' : 'no'
                    }
                })
            }
        case types.UPDATE_EVENT_REGISTRATION:
            return {
                ...state,
                eventRegistrations: state.eventRegistrations.map(reg => {
                    if (reg.id === action.updatedEventRegistration.id) {
                        return {
                            ...action.updatedEventRegistration, bubble_user: action.updatedEventRegistration.bubble_user
                        }
                    }
                    return reg;
                })
            }
        case types.SET_USER_LOGGED_IN_ACTION:
            return {...state, loggedIn: action.flag};
        case types.SET_CHECKIN_STEPS:
            return {...state, steps: action.steps};
        case types.SET_CHECKIN_OPTIONS:
            return {...state, options: action.options};
        case types.SET_USER_ONLINE:
            return {...state, userOnline: true};
        case types.SET_CHECK_IN_READY:
            return {...state, checkinReady: action.isReady};
        case types.SET_EVENT_REGISTRATION_INFO:
            return {...state, eventRegistration: action.registration};
        case types.SET_EVENT_REGISTRATIONS :
            return {...state, eventRegistrations: action.registrations};
        case types.UPDATE_CHECK_IN_CURRENT_STEP:
            return {...state, eventRegistration: {...state.eventRegistration, currentStep: action.currentStep}};
        case types.SET_CURRENT_SESSION_NUMBER :
            return {...state, currentSessionNumber: action.sessionNumber, fishbowlFunctionalityBlocked: false};
        case types.SET_CURRENT_EVENT_SESSIONS :
            return {...state, eventSessions: action.sessions};
        case types.SET_CHECKIN_FISHBOWL_POPUP:
            return {...state, showCheckinFishbowlPopup: action.visible, hideCheckinFishbowlPopup: false};
        case types.SET_CHECKIN_FISHBOWL_MINI_POPUP:
            return {...state, showCheckinFishbowlMiniPopup: action.visible};
        case types.SET_TARGET_JOIN_POPUP:
            return {...state, showTargetJoinPopup: action.visible, hideTargetJoinPopup: false};
        case types.SET_TARGET_JOIN_MINI_POPUP:
            return {...state, showTargetJoinMiniPopup: action.visible};
        case types.HIDE_CHECKIN_FISHBOWL_POPUP:
            return {...state, hideCheckinFishbowlPopup: true};
        case types.HIDE_TARGET_JOIN_POPUP:
            return {...state, hideTargetJoinPopup: true};
        case types.SET_FISHBOWL_FUNCTIONALITY_BLOCKED:
            return {...state, fishbowlFunctionalityBlocked: true};
        case types.SET_FISHBOWL_MINI_SEARCH_MODE:
            return {...state, fishbowlMiniSearchMode: action.flag};
        case types.SET_NEED_SHOW_GREEN_SCREEN:
            return {...state, needShowGreenScreen: action.flag};
        case types.SET_ORGANIZER_VIDEO:
            return {...state, videoSources: {...state.videoSources, organizerVideo: action.source}};
        case types.SET_AVAILABLE_TO_MATCH:
            return {...state, availableToMatch: action.available};
        case types.SET_DETECT_RTC_DATA:
            return {...state, detectRtcData: action.data};

        case types.SET_PREVIOUS_MET_USERS_IDS:
            return {...state, previouslyMetUsersIds: action.ids};

        case types.SET_ONLINE_USERS_IDS:
            return {...state, onlineUsersIds: action.ids};

        case types.SET_KICKOFF_IS_OVER:
            return {...state, kickoffIsOver: true};
        case types.SET_KICKOFF_IS_HIDDEN:
            return {...state, isHiddenKickoff: action.isHidden};
       case types.SET_LAST_MEET_OVER:
            return {...state, lastMeetOver: true};
      case types.SET_IN_PROGRESS_CHANGE_STEP:
            return {...state, inProgressChangeStep: action.flag};
        case types.SET_LAST_MEET_PASSED:
            return {...state, lastMeetPassed: true};

        default:
            return state
    }
}
